import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';
import getConfig from 'next/config';
import styles from './carousel.module.scss';
import Link from 'next/link';
import Image from 'next/image';

const {publicRuntimeConfig} = getConfig();

const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str) => typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

export default function Carousel({carousel, autoplay = 5000}) {
    if (!carousel || carousel.length < 1) {
        carousel = [{
            url: `${publicRuntimeConfig.basePath ?? ''}/`,
            img: `${publicRuntimeConfig.basePath ?? ''}/img/welcome.png`,
        }, {
            url: `${publicRuntimeConfig.basePath ?? ''}/`,
            img: `${publicRuntimeConfig.basePath ?? ''}/img/coding.jpg`,
        }];
    }
    return (
        /*
        <FlatCarousel autoplayInterval={5000} className={styles.carousel}>
            {carousel.map((value, index) => (
                <a
                    key={index}
                    draggable={false}
                    href={value.url}
                    className='carousel-item'
                    style={{ backgroundImage: "url(" + value.img + ")" }}
                >
                </a>
            ))}
        </FlatCarousel>
        */
        <Swiper
            slidesPerView={1}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            autoplay={{ delay: autoplay }}
            className={styles.carousel}
        >
            {carousel.map((value, index) => (
                <SwiperSlide
                    key={value}
                    virtualIndex={index}
                    draggable={false}
                    className={styles.swiperSlide}
                >
                    <Link href={value.url} passHref>
                        <a>
                            <Image
                                src={value.img}
                                alt={value.url}
                                layout="fill"
                                objectFit='cover'
                                placeholder="blur"
                                blurDataURL={`data:image/svg+xml,base64,${toBase64(shimmer(700, 475))}`}
                            />
                        </a>
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}