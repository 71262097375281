import * as React from 'react';
import {
    Modal,
    Button,
    Col,
    Text,
    Link
} from '@nextui-org/react';
import confetti from 'canvas-confetti';
import styles from './welcome-modal.module.scss';

class WelcomeModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false
        };
        this.modal = React.createRef();
    }
    
    async componentDidMount () {
        if (localStorage.getItem('welcome') != '1' && this.props.initial) {
            await this.setState({open: true});
        }
        if (this.modal.current && !this.modal.current.confetti) {
            this.modal.current.confetti = confetti.create(this.modal.current.confetti, {resize: true});
        }
        if (this.state.open && this.modal.current) {
            setTimeout(() => {
                this.modal.current.confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: { y: 0.8 },
                    zIndex: 9999
                });
            }, 0);
        }
    }

    render () {
        return (
            <div ref={this.modal}>
                <Modal
                    closeButton
                    aria-labelledby="welcome"
                    open={this.state.open}
                    className={styles.modal}
                    onClose={() => {
                        localStorage.setItem('welcome', '1');
                        this.setState({open: false});
                    }}
                >
                    <Modal.Header>
                        <Col>
                            <Text size={72}>🥳</Text>
                            <Text id="modal-title" size={21}>
                                社区公测啦！
                            </Text>
                            <Text size={14} color="#a1a1a1" del>有生之年能够公测真是太棒了</Text>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <Text>时隔<Text del>四年</Text>四个月的紧张开发，别针社区终于进入公测阶段，向广大 Scratcher 开放啦！</Text>
                        <Text>由于社区仍然处于测试阶段，因此如果你在浏览过程中遇到了问题请发邮件至 <Link href='mailto:abuse@ble.email'>abuse@ble.email</Link> 告诉我们！</Text>
                        <Text>最后，请不要忘记阅读我们的<Link href='/legal/rule'>社区守则</Link>, <Link href='/eula'>服务条款</Link>和<Link href='/legal/privacy'>隐私政策</Link>！</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto onPress={() => {
                            this.setState({open: false});
                        }}>
                            不再提醒
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default WelcomeModal;