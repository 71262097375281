/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import axios from '../utils/axios';
import {
    Container,
    Grid,
    Button,
    Spacer,
    Card,
    Text
} from '@nextui-org/react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Image from 'next/image';

import ProjectList from '@/components/Project/project-list/project-list';
import WelcomeModal from '@/components/Modal/welcome-modal/welcome-modal.jsx';
import recommendImage from '@/public/img/icons/recommend.svg';
import hotImage from '@/public/img/icons/hot.svg';
import newImage from '@/public/img/icons/new.svg';
import styles from '../styles/home.module.scss';
import { openSnackBar } from '../redux/snackbar';
import bindAll from 'lodash.bindall';
import plausible from '../utils/plausible';
import Request from '@/components/Core/request/request';
import Carousel from '@/components/Core/carousel/carousel';
import { FormattedMessage, injectIntl } from 'react-intl';
import serverFetch from '../utils/serverFetch';
import Toast from '../utils/toast';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.userInfo?.has_checkin
        };
        bindAll(this, [
            'handleCheckin'
        ]);
    }
    isBigMonth() {
        /** @type {import('react-intl').IntlShape} */
        const intl = this.props.intl;
        if (!intl.locale.startsWith('zh')) return '';
        return [1, 3, 5, 7, 8, 10, 12].indexOf(parseInt(dayjs().format('M'))) !== -1 ? '大' : '小';
    }
    handleCheckin() {
        const { intl } = this.props;
        // plausible('Checkin click', {userId: this.props.isLogin ? this.props.userInfo.id : null});
        if (!this.props.isLogin) {
            return Toast(intl.formatMessage({
                id: 'home.checkin.login',
                defaultMessage: '请先登录'
            }), 'warning');
        }
        axios.post('user/checkin')
            .then(() => {
                Toast(intl.formatMessage({
                    id: 'home.checkin.success',
                    defaultMessage: '签到成功！'
                }), 'success');
            })
            .catch(err => {
                const errorMsg = err.message;
                if (errorMsg === 'Already checkin') {
                    Toast(this.props.intl.formatMessage({
                        id: 'home.checkin.already',
                        defaultMessage: '你已经签过到了~'
                    }), 'warning');
                } else {
                    Toast(this.props.intl.formatMessage({
                        id: 'home.checkin.fail',
                        defaultMessage: '签到失败'
                    }), 'error');
                }
            })
            .finally(() => {
                this.setState({
                    checked: true
                });
            });
    }
    componentDidMount() {

    }

    render() {
        if (this.props.isDown) return (
            <Request isDown />
        );
        const {
            userInfo,
            isLogin,
            recommend,
            hot,
            newest
        } = this.props;
        /** @type {import('react-intl').IntlShape} */
        const intl = this.props.intl;
        return (
            <Container css={{ paddingTop: '20px' }} fluid className={styles.topbox}>
                <div>
                    <Grid.Container gap={2} justify="center" wrap='wrap'>
                        <Grid xs={100} sm={9}>
                            <Carousel carousel={/*this.props.carousel*/undefined} />
                        </Grid>
                        <Grid xs={100} sm={3}>
                            <Card className={styles.checkin}>
                                <div className={styles.checkinText}>
                                    <FormattedMessage
                                        id="home.checkin.welcome"
                                        defaultMessage="欢迎回来"
                                    />
                                    <div className={styles.username}>
                                        {isLogin ? userInfo.name : intl.formatMessage({ id: 'home.checkin.guest', defaultMessage: '游客' })}
                                    </div>
                                </div>
                                <div className={styles.date}>
                                    <div>{dayjs().format("MMMM") + this.isBigMonth()}</div>
                                    <div>{dayjs().format("DD")}</div>
                                    <div>{dayjs().format("dddd")}</div>
                                </div>
                                <div className={styles.checkinButton}>
                                    <Button onPress={this.handleCheckin} disabled={this.state.checked} auto className='umami--click--checkin-button' data-umami-event="checkin-button">
                                        <Spacer x={1} />
                                        <FormattedMessage
                                            id="home.checkin.button" defaultMessage='签到'
                                        />
                                        <Spacer x={1} />
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid.Container>
                </div>
                <div className={styles.projects}>
                    <ProjectList projectData={recommend} isLoading={false} optimizedImage>
                        <div className={styles.projectlist}>
                            <Text size={20}>
                                <div className={styles.projectlist_icon}>
                                    <Image
                                        objectFit="cover"
                                        layout="fill"
                                        src={recommendImage}
                                        alt={intl.formatMessage({
                                            id: "home.projectlist.recommended",
                                            defaultMessage: `小编推荐`,
                                        })}
                                    />
                                </div>
                                <FormattedMessage
                                    id='home.projectlist.recommended'
                                    defaultMessage='小编推荐'
                                />
                            </Text>
                        </div>
                    </ProjectList>
                    <ProjectList projectData={hot} isLoading={false} optimizedImage>
                        <div className={styles.projectlist}>
                            <Text size={20}>
                                <div className={styles.projectlist_icon}>
                                    <Image
                                        objectFit="cover"
                                        layout="fill"
                                        src={hotImage}
                                        alt={intl.formatMessage({
                                            id: "home.projectlist.hot",
                                            defaultMessage: `热门作品`,
                                        })}
                                    />
                                </div>
                                <FormattedMessage
                                    id='home.projectlist.hot'
                                    defaultMessage='热门作品'
                                />
                            </Text>
                        </div>
                    </ProjectList>
                    <ProjectList projectData={newest} isLoading={false} optimizedImage>
                        <div className={styles.projectlist}>
                            <Text size={20}>
                                <div className={styles.projectlist_icon}>
                                    <Image
                                        objectFit="cover"
                                        layout="fill"
                                        src={newImage}
                                        alt={intl.formatMessage({
                                            id: "home.projectlist.new",
                                            defaultMessage: `最新作品`,
                                        })}
                                    />
                                </div>
                                <FormattedMessage
                                    id='home.projectlist.new'
                                    defaultMessage='最新作品'
                                />
                            </Text>
                        </div>
                    </ProjectList>
                </div>
                <WelcomeModal initial={false} />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    isLogin: state.auth.user.isLogin,
    userInfo: state.auth.user.userInfo
});

const mapDispatchToProps = dispatch => ({
    openSnackBar: (message, type) => dispatch(openSnackBar(type, message))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home));

export async function getServerSideProps({ res }) {
    res.setHeader(
        'Cache-Control',
        'public, s-maxage=120, stale-while-revalidate=220'
    );
    try {
        // const carousel = await axios.get('carousel/get');
        const recommend = await serverFetch('favorite/getCollectionList?id=21&page=1&limit=12', null, 'GET');
        const hot = await serverFetch('project/getHotRank?limit=12&page=1', null, 'GET');
        const newest = await serverFetch('project/getNewest?limit=12&page=1', null, 'GET');
        return {
            props: {
                // carousel: carousel.data,
                recommend: recommend.projects ?? [],
                hot: hot.projects ?? [],
                newest: newest.projects ?? []
            }
        };
    } catch (e) {
        return {
            props: {
                isDown: true,
                hideMenuBar: true,
                hideFooter: true
            }
        };
    }
}